.header {
  position: relative;
  z-index: 10;

  & #overlay {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.4);
    pointer-events: auto;
    transition: 0.2s ease-out;

    &:global(.hidden) {
      background: rgba(0, 0, 0, 0);
      pointer-events: none;
    }
  }
}

#headerTop {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  gap: 40px;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04);

  & :global(.content-container) {
    height: 100%;
    width: min(calc(var(--container-width) - 35px), 100%);
    padding-right: 0;
    margin-right: max(calc(50% - var(--container-width) / 2 + 35px), 0px);
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 2;
  }

  & .left {
    display: flex;
    flex-direction: row;
    gap: 20px;
    height: 100%;

    & .logo {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 100%;
      width: 170px;
      gap: 12px;
      padding: 17.5px 0;
      border-left: none;

      & img {
        height: 100%;
        object-fit: contain;
      }

      & .text {
        display: flex;
        color: black;
        flex-direction: column;
        align-content: center;

        & .upper {
          font-size: 25px;
          font-weight: 700;
          line-height: 26px;
          margin-left: -2px;
        }

        & .lower {
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 4px;
          line-height: 10px;
        }
      }
    }
  }

  & .right {
    height: 100%;
    display: flex;
    flex-direction: row;
    border-right: 1px solid var(--brand-grey-hover);

    & #user {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 15px;
      font-size: 15px;
      position: relative;

      & .name {
        font-weight: 600;
        line-height: 1.2;
        font-size: 16px;
        width: 170px;

        & .unit {
          font-size: 12px;
          font-weight: 500;
          line-height: 1.2;
          margin-top: 3px;
          color: #888;
          text-overflow: ellipsis;
          max-height: 40px;
          overflow: hidden;
          & strong {
            color: black;
            font-weight: 900;
          }
        }
      }
    }

    & .logout {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: center;
      justify-content: center;

      &:hover {
        background: #f2f2f2;
      }
    }
  }

  & .right > *,
  & .left > * {
    border-left: 1px solid var(--brand-grey-hover);
    padding: 0 20px;
    cursor: pointer;
  }
}

#menuContainer {
  background: white;
}

:global {
  & #menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 56px;
    justify-content: space-between;

    & #menu-left {
      height: 100%;

      & ul {
        all: unset;
        display: grid;
        grid-auto-flow: column;
        height: 100%;
        gap: 32px;

        & li {
          all: unset;
          display: flex;
          align-items: center;
          gap: 8px;
          cursor: pointer;
          position: relative;
          font-size: 15px;
          font-weight: 600;
          height: 100%;
          transition: background 0.08s;

          & a:before {
            background-color: var(--brand-secondary);
            border-radius: 6px/4px 0px;
            bottom: -3px;

            /* Dziwny glitch w renderze jest bez tego. */
            border: rgba(0, 0, 0, 0.01) solid 0.0001px;
            opacity: 0;

            display: block;
            height: 3px;
            left: 50%;
            transform: skew(-25deg) translateX(-50%);
            margin-top: 16px;
            position: absolute;
            width: 0;
            content: '';
          }

          &.current {
            a {
              color: var(--brand-secondary);

              &:before {
                padding-left: 4px;
                content: '';
                width: 100%;
                opacity: 1;
                animation: animateWidth 0.3s ease-in-out;
              }
            }
          }
        }
      }
    }

    & #menu-right {
      float: right;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}

.tooltipMenu {
  position: absolute;
  display: none;
  flex-direction: column;
  right: 0;
  top: 80px;
  width: 100%;
  background: white;
  word-wrap: break-word;
  z-index: 1;

  & .selectedUnit {
    font-weight: bold;
  }

  &:before {
    content: '';
    display: flex;
    position: absolute;
    top: -6px;
    left: 34px;
    z-index: 1;
    height: 12px;
    width: 12px;
    border-bottom: 1px solid var(--support-grey-light);
    border-right: 1px solid var(--support-grey-light);
    background: white;
    transform: scale(1) rotate(50deg) skewX(10deg);
    animation: larger 0.3s ease-out;
    @keyframes larger {
      from {
        transform: scale(0) rotate(50deg) skewX(13deg);
      }
      to {
        transform: scale(1) rotate(50deg) skewX(13deg);
      }
    }
  }
}

:global(.withArrow.current) .tooltipMenu {
  display: flex;
  animation: animateHeight 0.3s ease-out;
}

.tooltipHeader {
  position: relative;
  width: calc(100% - 20px);
  background: white;
  font-weight: bold;
  text-align: center;
  color: black;
  border-bottom: 1px solid var(--support-grey-light);
  margin: 0 10px;
  padding: 12px 0;
}

.tooltipBody {
  position: relative;
  width: 100%;
  background: white;
  font-size: 13px;
  text-align: left;
  scrollbar-color: #003574 white;
  scrollbar-width: thin;

  & ul {
    padding: 0;
  }

  & strong {
    color: black;
    font-weight: 900;
  }
}

.tooltipBody ul li {
  list-style: none;
  padding: 11px 13px;
  font-size: 12px;
  line-height: 16px;
  color: #555;

  &:hover {
    background-color: var(--support-grey-light);
  }

  scrollbar-color: #003574 white;
  scrollbar-width: thin;
}

:global {
  @keyframes animateWidth {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
}

@keyframes animateRotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}

@keyframes animateHeight {
  from {
    transform: scaleY(0);
    transform-origin: top;
    opacity: 0;
  }
  to {
    transform: scaleY(1);
    transform-origin: top;
    opacity: 1;
  }
}
