.vo-icon {
  height: 23px;
  width: 23px;
  line-height: 21px;
  padding: 0;
  background: linear-gradient(to top, #f2f2f2 3%, #cacaca 29%, #e6e6e6 58%, var(--pko-white) 100%);
  border: 1px solid var(--pko-gray);
  border-radius: 4px;
  float: left;
  color: var(--pko-black);
  text-align: center;
}
.vo-icon:hover {
  background: linear-gradient(to top, #004187 0%, #001437 10%, #003b81 100%);
  border-color: #003b81;
  color: var(--pko-white);
}
