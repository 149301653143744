.slideout-wrapper.is-active {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9;
}

.slideout-container {
  position: absolute;
  left: 0;
  height: 100%;
  width: calc(100% - 10px);
  background-color: var(--pko-white);
  box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.32);
  transform: translate3d(50%, 0, 0);
  opacity: 0;
  transition: all .3s ease-in .5s;
  padding: 2em 1em;
  overflow: auto;
  border-radius: 8px;
}

.slideout-container.is-active {
  transform: translate3d(0,0,0);
  opacity: 1;
}


.vo-slideout-close-icon {
  position: absolute;
  right: 28px;
  top: 24px;
  background: transparent;
  color: darkgray;
  font-size: 24px;
  height: 32px;
  width: 32px;
  border: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  z-index: 1;
}

.vo-slideout-close-icon.icon-window-close:before {
  width: 100%;
}

.vo-slideout-header {
  margin: 0;
  padding: 0;
  display: block;
  font-family: var(--pko-font-family);
  font-size: 15px;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: -5px;
}

.vo-slideout-content {
  background-color: var(--pko-white);
}

.vo-slideout-content h2 {
  clear: both;
  font-family: var(--pko-font-family);
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 0px;
  margin: 0px;
}