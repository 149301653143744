html {
  scrollbar-base-color: #e0ebef;
}

.clr {
  clear: both;
}

.buttons a {
  text-decoration: underline;
}

div.buttons-inline {
  padding-top: 8px;
  clear: none;
}

.button-small {
  background: url('../images/btn-bg-17.png') repeat-x scroll 0 bottom transparent;
  border: 1px solid #a9d6ff;
  color: #2e4961;
  cursor: pointer;
  font-size: 10px;
  font-weight: bold;
  padding: 2px 0;
  min-width: 100px;
}

.button-small-check {
  background: url('../images/btn-bg-17.png') repeat-x scroll 0 bottom transparent;
  border: 1px solid #a9d6ff;
  color: #2e4961;
  cursor: pointer;
  font-size: 10px;
  font-weight: bold;
  padding: 2px 0;
}

.buttons a.button {
  text-decoration: none;
  display: inline-block;
  height: 18px;
  vertical-align: top;
  color: #2e4961;
}

.button,
buttons a.button {
  margin-left: 3px;
  width: auto;
  overflow: visible;
  padding: 5px 15px !important;
  text-align: center;
}

.button.suggested,
.button-small.suggested,
buttons a.button.suggested {
  color: red;
}

.content-panel h1 {
  color: black;
  font-size: 48px;
  font-weight: 500;
  margin: 70px 0 15px;
  line-height: 56px;
  padding-bottom: 3px;
}

.content-panel {
  /*background: white;*/
  /*border: 1px solid #e5e5e5;*/
  /*border-radius: 4px;*/
  /*padding: 16px 16px 24px;*/
}

.content-panel h3 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.content-panel h4 {
  font-size: 12px;
  padding: 5px 0;
  clear: both;
}

.content-panel .display {
  min-height: 18px;
}

.content-panel .readonly {
  background-color: #f9f9f9;
}

.singlePanel {
  width: 100%;
}

.singlePanel .item {
  padding: 8px 16px;
  margin: 0;
}

.singlePanel h1 {
  font-size: 11px;
  padding: 6px 8px;
  background-color: #73b1e8;
  color: white;
}

#top.logoNemo {
  background: url('../images/nmo-logo.png') -12px no-repeat !important;
}

#top.logoPlatin {
  background: url('../images/platin-logo.png') -12px no-repeat !important;
}

#logoLink {
  position: absolute;
  float: left;
  display: block;
  left: 14px;
  top: 6px;
  height: 50px;
  width: 164px;
}

#submenu2 li a {
  white-space: nowrap;
}

#submenu2 li.active {
  text-transform: uppercase;
  background-color: #efefef;
}

#content .box1,
#content .box2 {
  margin: 0;
}

#content .box {
  padding-bottom: 10px;
}

.msg-green,
.msg-gree-2,
.msg-orange,
.msg-red {
  margin: 0 auto 10px;
}

li.inactive,
li.inactive:hover {
  background: #eee;
  border-color: #afafaf;
}

li.inactive a {
  color: #afafaf !important;
}

label.error {
  color: #fb5555;
  font-weight: bold;
}

select.error {
  background-color: #fdf0f0;
  border: 1px solid #f3bbbb;
}

.gw {
  color: #fb5555;
}

.buttonsRemove {
  float: left;
  margin-top: 10px;
}

.actRow {
  background-color: #ffecd2;
  font-weight: bold;
}

.spacer {
  clear: both;
  height: 15px;
  font-size: 0;
}

#sidebar ul.blue5 li span.catName {
  float: left;
}

#sidebar ul.blue5 li.l2 span.catName {
  font-weight: normal;
}

div.radio table tr td input[type='radio'] {
  margin: 0;
  padding-left: 0;
}

.singlePanel .item h2 {
  font-size: 11px;
  font-weight: bold;
  margin-bottom: 8px;
  text-align: left;
  text-transform: none;
}

.tooltipHeader,
.tooltipBody {
  padding-left: 16px;
  padding-right: 16px;
}

.tooltipMenuHandler {
  position: relative;
}

.tooltipMenuHandler a {
  cursor: pointer;
}

.tooltipMenuHandler img {
  position: relative;
  vertical-align: top;
}

.tooltipMenuHandler .tooltipMenu {
  position: absolute;
  display: none;
  top: 8px;
  right: 0;
}

.tooltipMenuHandler .tooltipHeader,
.tooltipMenuHandler .tooltipBody {
  padding-left: 0;
  padding-right: 0;
}

div.pieChartLegend {
  background-color: #00b8bf;
  width: 15px;
  height: 15px;
  float: left;
  margin-right: 10px;
}

.progressBarOutside {
  display: inline;
  width: 102px;
  height: 11px;
  padding: 1px;
  background-image: url('../images/progress-bar-filled.png');
  float: right;
  margin-top: 1px;
  margin-left: 4px;
}

.nobackground {
  background: 0;
}

.noborder {
  border: 0;
}

li.noArrow {
  background-image: none !important;
}

#sidebar ul.blue5 li a {
  width: 100px;
}

.noBP {
  background: url('../images/NoBP.png') no-repeat;
  width: 35px;
  height: 16px;
  margin-right: 10px;
  float: right;
}

.exSKOK {
  background: url('../images/exSKOK.png') no-repeat;
  width: 44px;
  height: 16px;
  margin-right: 10px;
  float: right;
}

table td a {
  border-bottom: 0;
}

.radioTable td {
  padding: 4px 0;
}

h2 {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  padding: 6px 0;
  margin-bottom: 6px;
}

.tbl2 {
  width: 100%;
}

.tbl2 a {
  text-decoration: underline;
}

.tbl2 thead th {
  text-align: center;
  background-color: #d5ebff;
  padding: 5px;
  border: 1px solid #d5ebff;
}

.tbl2 td {
  border: 1px solid #d5ebff;
  padding: 5px;
}

.tbl2 tr:hover,
.tbl2 tr:hover td {
  background-color: #d5ebff;
}

td.colCenter {
  padding: 4px;
  text-align: center;
}

td.colLeft {
  padding: 4px;
  text-align: left;
}

td.colRight {
  padding: 4px;
  text-align: right;
}

td.colAction {
  padding: 4px;
  text-align: center;
  width: 70px;
}

td.colAction a {
  margin-left: 3px;
  margin-right: 3px;
}

td.colDate {
  padding: 4px;
  text-align: center;
  width: 100px;
}

td.colLp {
  width: 20px;
}

td.colAddress {
  width: 320px;
}

td.colLongAddress {
  width: 550px;
}

td.colPesel {
  width: 70px;
}

td.colAccount {
  width: 150px;
}

td.important {
  background: #ffd;
}

div.reportNavPanel {
  padding: 8px;
}

div.reportNavPanel table td {
  padding: 4px;
}

div.reportNavPanel table.pagination tr {
  height: 18px;
}

div.reportNavPanel table.pagination td {
  padding: 0;
  width: 20px;
}

div.reportNavPanel table.pagination input {
  width: 26px;
  text-align: right;
}

div.reportNavPanel table.pagination a {
  color: white;
  font-weight: bold;
  font-size: 14px;
  display: block;
  text-decoration: none;
}

#reportContext table {
  border-collapse: collapse;
}

.calendarPaging {
  text-align: center;
  margin-top: 8px;
}

.calendarPaging a:hover {
  text-decoration: none;
}

.calendarPaging img {
  vertical-align: middle;
  margin: 0 8px;
}

#sidebar ul.sql_criteria {
  border: 1px solid #d5ebff;
  padding: 5px;
  background-color: #f0f0f0;
}

#sidebar ul.sql_criteria ul {
  display: inline-block;
  width: 156px;
}

#sidebar ul.sql_criteria li {
  padding: 0;
  background: 0;
}

#sidebar ul.sql_criteria li ul {
  padding: 0 3px 5px;
}

#sidebar ul.sql_criteria li ul li ul {
  padding: 0 3px 5px;
  border-bottom: 1px solid #d5ebff;
}

#sidebar .sql_criteria ul li.name {
  font-weight: bold;
}

#sidebar ul.sql_criteria input[readonly='readonly'] {
  color: inherit;
  background: 0;
}

.TableView {
  width: 790px;
  float: right;
}

.TableView table.tbl2 {
  empty-cells: show;
  border-collapse: collapse;
  width: 100%;
}

.TableView table.tbl2 div {
  margin: 2px;
  padding: 1px 4px;
  white-space: nowrap;
  overflow: hidden;
}

.WeekView {
  margin: 1px 0 0 1px;
  empty-cells: show;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

.WeekView tr td {
  vertical-align: top;
  border: 1px solid #d0d0d0;
  height: 0;
}

.WeekView tr td.colGodz {
  text-align: center;
}

.WeekView tr td.colWeekend,
.WeekView tr td.gray {
  background-color: #eee;
}

.WeekView tr.hidden td {
  border: 0;
  border-top: 1px solid #d0d0d0;
  height: 0;
}

.WeekView tr.hidden td.colGodz {
  width: 45px;
}

.WeekView tr.hidden td.colWeekend {
  width: 60px;
}

.WeekView tr.header td {
  padding: 4px 0;
  font-weight: bold;
  text-align: center;
  height: 28px;
  vertical-align: middle;
}

.WeekView div {
  margin: 2px;
  padding: 1px 4px;
  white-space: nowrap;
  overflow: hidden;
}

.WeekView tr div a:hover b {
  text-decoration: none;
}

.WeekView tr.strefaJednodniowa {
  height: 34px;
}

.WeekView tr.strefaJednodniowa div a b {
  display: block;
  overflow: hidden;
  cursor: pointer;
}

.WeekView tr.strefaWielodniowa div {
  height: 16px;
  margin-bottom: 0;
}

.WeekView tr.strefaWielodniowa td {
  border-top: 0;
  border-bottom: 0;
}

.WeekView .calendarItemMenu {
  margin: 0;
  padding: 0;
  position: relative;
  line-height: 0;
  overflow: visible;
}

.WeekView .calendarItemMenu .add_item {
  position: absolute;
}

.WeekView .calendarItemMenu ul {
  background: none repeat scroll 0 0 white;
  border: 1px solid #555;
  display: none;
  line-height: 1em;
  list-style: none outside none;
  position: absolute;
  top: -5px;
  left: -20px;
}

.WeekView .calendarItemMenu ul li {
  padding: 2px 4px;
}

.WeekView .calendarItemMenu ul li:hover {
  background: #eee;
}

.MonthView {
  margin: 1px 0 0 1px;
  empty-cells: show;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

.MonthView tr td {
  vertical-align: top;
  border: 1px solid #d0d0d0;
  height: 77px;
}

.MonthView tr th {
  padding: 4px 0;
  font-weight: bold;
  text-align: center;
  height: 28px;
  vertical-align: middle;
  border: 1px solid #d0d0d0;
}

.MonthView tr td.colWeekend,
.MonthView tr th.colWeekend {
  background-color: #eee;
}

.MonthView div {
  margin: 0;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
}

.MonthView .day {
  background-color: #e0e0e0;
  border-bottom: 1px solid #d0d0d0;
  padding: 2px 4px;
  margin: 0;
  margin-bottom: 2px;
}

.MonthView .item {
  width: 16px;
  height: 16px;
  float: left;
  margin-left: 2px;
  margin-bottom: 2px;
  padding: 0;
}

.MonthView td.gray {
  background-color: #e0e0e0;
}

.WeekView tr td div.mark,
.MonthView tr td div.mark {
  border: 1px solid #888;
}

.WeekView tr td.today,
.MonthView tr td.today {
  border: 2px solid #888;
}

.TableView div.grey,
.WeekView div.grey,
.MonthView div.grey {
  background-color: #eee;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.TableView div.yellow,
.WeekView div.yellow,
.MonthView div.yellow {
  background-color: #ffc;
  border: 1px solid #fec;
  border-radius: 4px;
}

.TableView div.green,
.WeekView div.green,
.MonthView div.green {
  background-color: #cfc;
  border: 1px solid #cec;
  border-radius: 4px;
}

.TableView div.green_hl,
.WeekView div.green_hl,
.MonthView div.green_hl {
  background-color: #cf9;
  border: 1px solid #cec;
  border-radius: 4px;
}

.TableView div.red,
.WeekView div.red,
.MonthView div.red {
  background-color: #f99;
  border: 1px solid #f77;
  border-radius: 4px;
}

.TableView div.orange,
.WeekView div.orange,
.MonthView div.orange {
  background-color: #ffdaaf;
  border: 1px solid #ffbaaf;
  border-radius: 4px;
}

.TableView div.blue,
.WeekView div.blue,
.MonthView div.blue {
  background-color: #c9e4ff;
  border: 1px solid #a9e4ff;
  border-radius: 4px;
}

.TableView div.brown,
.WeekView div.brown,
.MonthView div.brown {
  background-color: #ff4040;
  border: 1px solid #a52a2a;
  border-radius: 4px;
}

.TableView div.dark_green,
.WeekView div.dark_green,
.MonthView div.dark_green {
  background-color: #3f0;
  border: 1px solid #3d3;
  border-radius: 4px;
}

.taskComments {
  border: 1px solid #ccc;
  height: 114px;
  overflow: scroll;
  padding: 3px;
  width: 338px;
  background: #f9f9f9;
  font-weight: normal;
}

.taskComments .header {
  font-weight: bold;
}

.taskComments hr {
  border-top: 1px dotted #73b1e8;
  margin: 3px 0;
}

div.display,
div.input,
div.textarea,
div.select,
div.radio,
div.check,
div.slider {
  float: left;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

div.input:checkbox {
  width: auto;
}

div.input label,
div.textarea label,
div.select label,
div.slider label {
  display: block;
}

div.input input,
div.select select,
div.textarea textarea {
  text-align: left;
}

div.display {
  width: 370px;
  min-height: 36px;
}

div.displayMinHeight {
  height: 36px;
  float: right;
  width: 1px;
}

div.heightLikeInput {
  height: 36px;
}

.displayAsLink {
  text-decoration: underline;
}

div.displayAsLink strong {
  cursor: pointer;
  text-decoration: underline;
}

div.displayAsLink:hover {
  text-decoration: none;
}

div.input,
div.select,
div.check,
div.height1x,
div.slider {
  height: 36px;
}

div.textarea,
div.height2x {
  height: 72px;
}

div.height3x {
  height: 108px;
}

div.height4x {
  height: 144px;
}

div.input,
div.textarea,
div.select,
div.radio,
div.check,
div.module1x,
div.slider {
  width: 185px;
}

div.module2x {
  width: 370px;
}

div.module3x {
  width: 555px;
}

div.module4x {
  width: 740px;
}

div.module5x {
  width: 925px;
}

div.input input,
div.textarea textarea,
div.module1x input {
  width: 155px;
}

div.select select,
div.module1x select {
  width: 161px;
  padding-left: 0;
}

div.module2x input {
  width: 340px;
}

div.module2x select {
  width: 345px;
}

div.module3x input {
  width: 525px;
}

div.module3x select {
  width: 531px;
}

div.module4x input {
  width: 710px;
}

div.module4x select {
  width: 716px;
}

div.module5x input,
div.module5x textarea {
  width: 895px;
}

div.module5x select {
  width: 901px;
}

div.textarea textarea {
  height: 47px;
}

div.height3x textarea {
  height: 82px;
}

div.check label {
  margin-top: 0;
  display: inline;
}

div.check input,
div.radio input {
  width: 18px;
  height: 18px;
  margin-top: 0;
  clear: both;
}

div.slider label {
  margin-bottom: 5px;
}

div.slider {
  overflow: visible;
}

div.slider .ui-slider-scale span.ui-slider-label {
  font-size: 9px;
  white-space: nowrap;
}

div.shuttle.module1x .src,
div.shuttle.module1x .dst {
  width: 82px;
}

div.shuttle.module2x .src,
div.shuttle.module2x .dst {
  width: 168px;
}

div.shuttle.module3x .src,
div.shuttle.module3x .dst {
  width: 260px;
}

div.shuttle.module4x .src,
div.shuttle.module4x .dst {
  width: 352px;
}

div.shuttle.module5x .src,
div.shuttle.module5x .dst {
  width: 445px;
}

div.shuttle .srcFilter {
  width: 100px;
  margin-bottom: 5px;
}

div.shuttle.module2x .srcFilter {
  width: 30px;
}

div.shuttle.module1x .srcFilter {
  width: 16px;
}

div.shuttle img {
  cursor: pointer;
}

div.shuttle img.disabled {
  cursor: default;
  filter: alpha(opacity=20);
  opacity: 0.2;
}

div.shuttle .row2 {
  background-color: #f2f9f9;
}

div.tree ul {
  list-style: none;
}

div.tree ul ul {
  padding-left: 20px;
}

div.tree li.marked {
  font-weight: bold;
}

div.tree li.selected {
  font-weight: bold;
  color: #d00;
}

div.tree li {
  font-weight: normal;
  color: black;
}

div.tree li img {
  vertical-align: bottom;
}

div.tree .node-line {
  background: url('../images/nodeLine.gif') no-repeat 8px 0;
}

div.tree .node-last-line {
  background: url('../images/nodeLastLine.gif') no-repeat 8px -23px;
}

input.empty {
  font-style: italic;
  font-weight: normal;
  color: #8f8f8f;
}

textarea.error {
  border: 1px solid #f3bbbb;
  background-color: #fdf0f0;
}

#sidebar ul.blue li.active {
  /* 404 */
  /* background: url('./img/arrow-old.gif') 8px 9px no-repeat #a9d6ff; */
}

.rich-filter-input {
  width: 50px;
  text-align: left;
}

.dr-dscr-inact {
  cursor: pointer;
}

.dr-dscr-act {
  cursor: default;
}

.dr-dscr-button {
  cursor: pointer;
}

.rich-shuttle-list-content {
  border: 1px solid #d0d0d0;
  overflow: scroll;
}

.rich-shuttle-source-row-selected {
  background-color: #d0d0d0;
}

.rich-list-shuttle-button-content {
  cursor: pointer;
  width: 24px;
  text-align: center;
  background: 0;
}

.rich-shuttle-target-row-selected {
  background-color: #d0d0d0;
}

.rich-list-shuttle-caption {
  font-weight: bold;
}

.rich-shuttle-source-items,
.rich-shuttle-target-items {
  cursor: pointer;
}

.rich-shuttle-source-items td,
.rich-shuttle-target-items td {
  padding-left: 2px;
}

.rich-progress-bar-shell-dig {
  border-color: #bed6f8;
  color: #000;
  font-family: Arial, Verdana, sans-serif;
  font-size: 11px;
}

.rich-progress-bar-shell-dig {
  border: 1px solid;
  margin-bottom: 2px;
  overflow: hidden;
  position: relative;
}

.rich-progress-bar-block {
  white-space: nowrap;
}

.rich-progress-bar-width {
  width: 200px;
}

.rich-progress-bar-remained {
  background-color: #fff;
}

.rich-progress-bar-padding {
  padding: 0;
}

.rich-progress-bar-remained {
  font-weight: bold;
  position: relative;
  text-align: center;
}

.rich-progress-bar-width {
  width: 200px;
}

.rich-progress-bar-uploaded-dig {
  border-color: #bed6f8;
}

.rich-progress-bar-uploaded-dig {
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
}

.rich-progress-bar-completed {
  background-color: #e79a00;
  background-image: url('../images/progressBar.gif');
}

.rich-progress-bar-padding {
  padding: 0;
}

.rich-progress-bar-completed {
  background-repeat: repeat-x;
  font-weight: bold;
  text-align: center;
}

.rich-progress-bar-width {
  width: 200px;
}

.rich-tree-node-text {
  padding-left: 4px;
}

.rich-tree-node {
  margin-left: 0;
  padding-left: 0;
}

.rich-tree-node-children {
  margin-left: 7px;
  padding-left: 9px;
}

.dr-tree-h-ic-line {
  background-position: left center;
  background-repeat: repeat-y;
}

.dr-tree-h-ic-line-node,
.dr-tree-h-ic-line-last,
.dr-tree-h-ic-line-exp,
.dr-tree-h-ic-line-clp {
  background-position: center;
  background-repeat: no-repeat;
  width: 16px;
}

.dr-tree-h-ic-line {
  /* 404 */
  /* background-image: url('../images/nodePoint.gif'); */
}

.dr-tree-h-ic-line-node {
  background-image: url('../images/nodeLine.gif');
}

.dr-tree-h-ic-line-last {
  background-image: url('../images/nodeLastLine.gif');
}

.dr-tree-h-ic-line-exp {
  background-image: url('../images/nodeExpandedLine.gif');
}

.dr-tree-h-ic-line-clp {
  background-image: url('../images/nodeCollapsedLine.gif');
}

span[id$='productTree'] table td a,
.MonthView a,
.WeekView a {
  border-bottom: medium none;
}

div#scannedDocs {
  display: none;
  text-align: left;
  overflow: hidden;
}

div .docsList {
  border: 1px solid transparent;
}

div .docsList .separator {
  height: 0;
  font-size: 0;
  border-top: 1px solid #ccc;
  line-height: 0;
  clear: both;
  margin: 0 0 10px;
}

div .docsList .thumb {
  display: block;
  float: left;
  border: 3px solid #ccc;
  text-align: center;
  text-decoration: none;
  margin: 0 4px 10px;
  padding: 0;
  min-width: 170px;
  height: 60px;
  cursor: pointer;
  overflow: hidden;
}

div .docsList .thumbpage_n {
  background: url('../images/page_n.jpg') no-repeat;
}

div .docsList .thumbpage1 {
  background: url('../images/page1.jpg') no-repeat;
}

div .docsList .thumbpage2 {
  background: url('../images/page2.jpg') no-repeat;
}

div .docsList .thumbpage3 {
  background: url('../images/page3.jpg') no-repeat;
}

div .docsList .thumbpage4 {
  background: url('../images/page4.jpg') no-repeat;
}

div .docsList .thumb:hover {
  border: 3px solid #ff8080;
}

div#docImageFixedBackground {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 999;
  background-color: #333;
}

div#docImageContainer {
  display: none;
  position: absolute;
  z-index: 999;
  width: 100%;
  left: 0;
  top: 0;
  cursor: pointer;
  text-align: center;
  color: white;
  font-weight: bold;
}

div#docImageContainer a {
  color: white;
  font-weight: bold;
}

div#signatureImage img,
div#signatureImage canvas {
  margin: 4px 16px 16px;
}

.readonly {
  background-color: #efefef;
  color: #999;
}

.switchSearch {
  display: inline;
  float: right;
  padding-right: 10px;
  margin-bottom: 0;
  margin-top: 8px;
  background: transparent;
  position: relative !important;
  top: 0 !important;
}

.switchSearch a {
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
}

fieldset.label {
  background: 0;
  border: 0;
  margin-top: 0;
  padding: 0;
}

fieldset.label legend {
  border: 0;
  margin-bottom: 7px;
  font-weight: bold;
  text-transform: uppercase;
  background: none repeat scroll 0 50% transparent;
}

#sidebar ul.blue4 {
  border-bottom: 1px solid #d5ebff;
  background-color: #fff;
}

#sidebar ul.blue4 li {
  border-left: 1px solid #d5ebff;
  border-right: 1px solid #d5ebff;
  margin: 0;
  background-image: none;
}

#sidebar ul.blue4 li.group {
  background: url('../images/arrow-old.gif') no-repeat scroll 8px 9px #fff;
  text-transform: uppercase;
}

#sidebar ul.blue4 li.nogroup {
  background-color: #fff;
}

#sidebar ul.blue4 li.group:hover {
  background-color: #d5ebff;
}

#sidebar ul.blue4 li.nogroup:hover {
  background-color: #d5ebff;
}

#sidebar ul.blue4 a {
  float: left;
  width: 120px;
}

#sidebar ul.blue4 span {
  float: right;
}

#sidebar ul.blue4 span,
#sidebar ul.blue4 span a {
  color: #e61717;
  font-size: 95%;
}

.popup_ {
  width: 500px;
  position: fixed;
  top: 15%;
  left: 50%;
  z-index: 1000;
  background: white;
  padding: 0;
}

.popup_message {
  overflow: hidden;
}

.popup_fade {
  background-color: #000;
  filter: alpha(opacity=20);
  opacity: 0.2;
  display: block;
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 500;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.popup_close {
  background: url('../images/close_ico.png') no-repeat scroll 0 0 transparent;
  position: absolute;
  height: 16px;
  padding-right: 16px;
  top: 4px;
  right: 4px;
  cursor: pointer;
}

.popup_header {
  background-color: #73b1e8;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 5px 5px 10px;
}

div.innerActivator {
  float: right;
  font-weight: bold;
}

div.rmsView table {
  border-collapse: collapse;
  margin-bottom: 20px;
  width: 940px;
}

div.rmsView table td {
  padding: 4px 2px;
}

div.rmsView table td input {
  margin: 0;
}

div.rmsView table td input[type='text'] {
  width: 95%;
  max-width: 95%;
}

div.rmsView table td select {
  width: 95%;
  max-width: 95%;
}

div.rmsView table td textarea {
  width: 95%;
  height: 100px;
  overflow: hidden;
}

div.rmsView table td textarea.middle {
  width: 95%;
  height: 60px;
}

div.rmsView table td textarea.small {
  width: 95%;
  height: 45px;
}

div.rmsView table td textarea.mini {
  width: 95%;
  height: 30px;
}

div.rmsView table td textarea.error {
  background-color: #fdf0f0;
  border: 1px solid #f3bbbb;
}

div.rmsView .left {
  text-align: left;
}

div.rmsView .right {
  text-align: right;
}

div.rmsView .center {
  text-align: center;
}

div.rmsView table td.size15px {
  width: 15px;
}

div.rmsView table td.size35px {
  width: 35px;
}

div.rmsView table td.size15pp {
  width: 15%;
}

div.rmsView table td.size25pp {
  width: 25%;
}

div.rmsView table td.size40pp {
  width: 40%;
}

div.rmsView table td.size50pp {
  width: 50%;
}

div.rmsView h1 {
  background-color: #73b1e8 !important;
  color: white !important;
  font-size: 11px !important;
  padding: 6px 8px !important;
  height: 12px !important;
  margin-bottom: 2px !important;
}

div.rmsView h1.noactive {
  background-position: 99% 50%;
  background-repeat: no-repeat;
  /* 404 */
  /* background-image: url('../images/arr-left.png'); */
}

div.rmsView h1.active {
  background-position: 99% 50%;
  background-repeat: no-repeat;
  /* 404 */
  /* background-image: url('../images/arr-down.png'); */
}

div.rmsView div.shblk {
  display: none;
}

div.rmsView .pointer {
  cursor: pointer;
}

div.rmsView table td input.srcFilter {
  width: 100px;
}

td.canceledNote {
  color: graytext;
}

a.canceledNote {
  color: graytext;
  text-decoration: none;
  text-decoration: underline;
}

.canceledNoteThrough {
  color: graytext;
  text-decoration: line-through;
}

p.noneP {
  margin-top: 20px;
  border-top: 1px solid #a9d6ff;
  text-align: left;
  padding-bottom: 30px;
}

.dataTables_wrapper {
  position: relative;
  clear: both;
  zoom: 1;
}

.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 250px;
  height: 30px;
  margin-left: -125px;
  margin-top: -15px;
  padding: 14px 0 2px 0;
  border: 1px solid #ddd;
  text-align: center;
  color: #999;
  font-size: 14px;
  background-color: white;
}

.dataTables_filter {
  width: 50%;
  float: right;
  text-align: right;
}

table.display {
  margin: 0 auto;
  clear: both;
  width: 100%;
}

table.display thead th {
  padding: 3px 18px 3px 10px;
  font-weight: bold;
  cursor: pointer;
  *cursor: hand;
  background-color: #d5ebff;
  border: 1px solid #d5ebff;
  padding: 5px;
  text-align: center;
}

table.display tfoot th {
  padding: 3px 18px 3px 10px;
  border-top: 1px solid black;
  font-weight: bold;
}

table.display td {
  padding: 3px 10px;
}

table.display td.center {
  text-align: center;
}

.sorting_asc {
  background: url('../images/sort_asc.png') no-repeat center right;
}

.sorting_desc {
  background: url('../images/sort_desc.png') no-repeat center right;
}

.sorting {
  background: url('../images/sort_both.png') no-repeat center right;
}

.sorting,
.sorting_asc,
.sorting_desc {
  padding-right: 15px !important;
}

.sorting_asc_disabled {
  background: url('../images/sort_asc_disabled.png') no-repeat center right;
}

.sorting_desc_disabled {
  background: url('../images/sort_desc_disabled.png') no-repeat center right;
}

.top,
.bottom {
  padding: 10px;
  border: 1px solid #ccc;
}

.top .dataTables_info {
  float: none;
}

.clear {
  clear: both;
}

.dataTables_empty {
  text-align: center;
}

tfoot input {
  margin: 0.5em 0;
  width: 100%;
  color: #444;
}

tfoot input.search_init {
  color: #999;
}

td.group {
  background-color: #d1cfd0;
  border-bottom: 2px solid #a19b9e;
  border-top: 2px solid #a19b9e;
}

td.details {
  background-color: #d1cfd0;
  border: 2px solid #a19b9e;
}

.paging_full_numbers {
  height: 22px;
  line-height: 22px;
  text-align: center;
  width: 100%;
}

.paging_full_numbers span.paginate_button,
.paging_full_numbers span.paginate_active {
  padding: 2px 5px;
  margin: 0 3px;
  cursor: pointer;
  *cursor: hand;
  font-weight: bold;
}

.paging_full_numbers span.paginate_button:hover {
  color: black;
  text-decoration: underline;
}

.paging_full_numbers span.paginate_active {
  text-decoration: underline;
}

table.KeyTable td {
  border: 3px solid transparent;
}

table.KeyTable td.focus {
  border: 3px solid #36f;
}

* html .hitarea {
  display: inline;
  float: none;
}

span.add_item {
  width: 7px;
  height: 7px;
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat 0 0;
  display: inline-block;
}

span.change {
  width: 12px;
  height: 12px;
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -8px 0;
  display: inline-block;
}

span.euro24 {
  width: 24px;
  height: 24px;
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -21px 0;
  display: inline-block;
}

span.forma_kontaktu_1 {
  width: 16px;
  height: 16px;
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -46px 0;
  display: inline-block;
}

span.forma_kontaktu_2 {
  width: 16px;
  height: 16px;
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -63px 0;
  display: inline-block;
}

span.forma_kontaktu_3 {
  width: 16px;
  height: 16px;
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -80px 0;
  display: inline-block;
}

span.forma_kontaktu_4 {
  width: 16px;
  height: 16px;
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -97px 0;
  display: inline-block;
}

span.forma_kontaktu_5 {
  width: 16px;
  height: 16px;
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -114px 0;
  display: inline-block;
}

span.forma_kontaktu_6 {
  width: 16px;
  height: 16px;
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -131px 0;
  display: inline-block;
}

span.important {
  width: 14px;
  height: 12px;
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -148px 0;
  display: inline-block;
}

span.page_process24 {
  width: 24px;
  height: 24px;
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -163px 0;
  display: inline-block;
}

span.search24 {
  width: 24px;
  height: 24px;
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -188px 0;
  display: inline-block;
}

span.search_user24 {
  width: 24px;
  height: 24px;
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -213px 0;
  display: inline-block;
}

span.typ_zadania_SIMPLE {
  width: 16px;
  height: 16px;
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -238px 0;
  display: inline-block;
}

span.typ_zadania_TRAINING {
  width: 16px;
  height: 16px;
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -255px 0;
  display: inline-block;
}

span.typ_zadania_VISITATION {
  width: 16px;
  height: 16px;
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -272px 0;
  display: inline-block;
}

span.typ_zadania_XSELL {
  width: 16px;
  height: 16px;
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -289px 0;
  display: inline-block;
}

span.typ_zadania_XSELL_OLD {
  width: 16px;
  height: 16px;
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -306px 0;
  display: inline-block;
}

span.typ_zadania_XSELL_OLD {
  width: 16px;
  height: 16px;
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -306px 0;
  display: inline-block;
}

span.xsell_XSELL_CAMPAIGN {
  width: 16px;
  height: 16px;
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -323px 0;
  display: inline-block;
}

span.xsell_XSELL_TASK {
  width: 16px;
  height: 16px;
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -340px 0;
  display: inline-block;
}

span.xsell_XSELL_BARGAIN_TASK {
  width: 16px;
  height: 16px;
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -340px 0;
  display: inline-block;
}

span.SIMULATION {
  width: 16px;
  height: 16px;
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -306px 0;
  display: inline-block;
}

span.adm {
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -357px 0;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.exit {
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -374px 0;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.fav {
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -391px 0;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.link {
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -408px 0;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.home-icon {
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -425px 0;
  width: 16px;
  height: 15px;
  display: inline-block;
}

span.information {
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -442px 0;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.cashier-menu {
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -459px 0;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.ftnet {
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -476px 0;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.exit2 {
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -493px 0;
  width: 16px;
  height: 16px;
  display: inline-block;
}

#top div.doradca {
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat 0 -45px !important;
}

#top div.klient {
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -21px -45px !important;
}

#top div.klient_PID {
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -42px -45px !important;
}

#top div.klient_PID_RW {
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -63px -45px !important;
}

#top div.klient_PID_RWS {
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -84px -45px !important;
}

#top div.klient_PRS {
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -105px -45px !important;
}

#top div.operator {
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -126px -45px !important;
}

#top div.sprawa {
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -147px -45px !important;
}

#top div.zadanie {
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -168px -45px !important;
}

#top div.pracownik {
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -189px -45px !important;
}

#top div.klient_OZSI {
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -210px -45px !important;
}

span.zoom_in-fff {
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -231px -42px;
  width: 12px;
  height: 12px;
  display: inline-block;
}

span.zoom_out-fff {
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -244px -42px;
  width: 12px;
  height: 12px;
  display: inline-block;
}

span.next {
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -257px -42px;
  width: 16px;
  height: 16px;
  padding-left: 10px;
  padding-right: 10px;
}

span.prev {
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -274px -42px;
  width: 16px;
  height: 16px;
  padding-left: 10px;
  padding-right: 10px;
}

span.progress-bar-empty {
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -291px -42px;
  width: 102px;
  height: 11px;
  display: inline-block;
}

span.progress-bar-filled {
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -394px -42px;
  width: 104px;
  height: 13px;
  display: inline-block;
}

#top div.szukaj {
  background: url('../images/platin-icons-sprite.png?q=-1') no-repeat -499px -45px !important;
}

span.sprite-f {
  width: 16px;
  height: 16px;
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat 0 -25px;
}

span.sprite-f50 {
  width: 16px;
  height: 16px;
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -17px -25px;
}

span.sprite-ff {
  width: 16px;
  height: 16px;
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -34px -25px;
}

span.sprite-ff50 {
  width: 16px;
  height: 16px;
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -51px -25px;
}

span.sprite-r {
  width: 16px;
  height: 16px;
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -68px -25px;
}

span.sprite-r50 {
  width: 16px;
  height: 16px;
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -85px -25px;
}

span.sprite-rr {
  width: 16px;
  height: 16px;
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -102px -25px;
}

span.sprite-rr50 {
  width: 16px;
  height: 16px;
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -119px -25px;
}

.closed {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -266px -25px;
  width: 9px;
  height: 9px;
  display: inline-block;
}

.opened {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -276px -25px;
  width: 9px;
  height: 9px;
  display: inline-block;
}

.blank {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -286px -25px;
  width: 9px;
  height: 9px;
  display: inline-block;
}

.wykrzyknik2 {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') -236px -25px;
  width: 13px;
  height: 13px;
  display: inline-block;
}

.wykrzyknik3 {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') -250px -25px;
  width: 15px;
  height: 16px;
  display: inline-block;
}

span.Credit-icon16 {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat 0 -102px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.arr-down {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -17px -102px;
  width: 12px;
  height: 12px;
  display: inline-block;
}

span.arr-left {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -30px -102px;
  width: 12px;
  height: 12px;
  display: inline-block;
}

span.blank_16 {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -43px -102px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.close_ico {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -60px -102px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.contact {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -77px -102px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.contactPassive {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -94px -102px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.contact_create-icon16 {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -111px -102px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.credit-card-icon_16 {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -128px -102px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.financial_terminal-icon12_14 {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -145px -102px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.insurance-icon16 {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -162px -102px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.mini-mifid {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -179px -102px;
  width: 40px;
  height: 14px;
  display: inline-block;
}

span.offer-icon16 {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -220px -102px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.phone_small {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -237px -102px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.pie-chart-icon16 {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -254px -102px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.process-icon16 {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -271px -102px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.product-icon16 {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -288px -102px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.refresh {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -305px -102px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.signature {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -322px -102px;
  width: 12px;
  height: 12px;
  display: inline-block;
}

span.iconAris {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat 0 -119px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.iconAurumPdf {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -17px -119px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.iconEDoradca {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -34px -119px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.iconEuro {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -51px -119px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.iconFolder {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -68px -119px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.iconFolderLeaf {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -85px -119px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.iconInfoPdf {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -102px -119px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.iconInfoRtf {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -119px -119px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.iconInfoWord {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -136px -119px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.iconLeaf {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -153px -119px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.iconPdf {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -170px -119px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.iconPlatinumPdf {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -187px -119px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.iconProduct {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -204px -119px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.iconRtf {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -221px -119px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.iconWord {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -238px -119px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.nodeCollapsedIcon {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -255px -119px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.nodeEmptyIcon {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -272px -119px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.nodeExpandedIcon {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -289px -119px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.nodeLastLine {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -306px -119px;
  width: 16px;
  height: 32px;
  display: inline-block;
}

span.dok {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat 0 -152px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.kontrakt2 {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -17px -152px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.kreska2 {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -34px -152px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.rachunek2 {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -51px -152px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.singer {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -68px -152px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.strzalka2 {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -85px -152px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.info_icon {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -102px -152px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.info_important_icon {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -119px -152px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.monitoring_icon {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -136px -152px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.client10 {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -153px -152px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.strony10 {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -170px -152px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.zastepowani {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -187px -152px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.product_type_ACCOUNTS {
  background: url('../images/product_type_ACCOUNTS.gif');
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.product_type_BONDS {
  background: url('../images/product_type_BONDS.gif');
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.product_type_CARDS {
  background: url('../images/product_type_CARDS.gif');
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.product_type_CREDIT_ACCOUNTS {
  background: url('../images/product_type_CREDIT_ACCOUNTS.gif');
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.product_type_FUNDS {
  background: url('../images/product_type_FUNDS.gif');
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.product_type_INSURANCES {
  background: url('../images/product_type_INSURANCES.gif');
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.product_type_SAVINGS {
  background: url('../images/product_type_SAVINGS.gif');
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.product_type_STRUCTURED_PRODUCTS {
  background: url('../images/product_type_STRUCTURED_PRODUCTS.gif');
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.product_type_TIME_ACCOUNTS {
  background: url('../images/product_type_TIME_ACCOUNTS.gif');
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.product_type_FACTORING {
  background: url('../images/product_type_FACTORING.gif');
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.product_type_INVESTMENT_INSURANCES {
  background: url('../images/product_type_INVESTMENT_INSURANCES.gif');
  width: 16px;
  height: 16px;
  display: inline-block;
}

span.pdo_yesyes {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -204px -152px;
  width: 34px;
  height: 16px;
  display: inline-block;
}

span.pdo_noyes {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -239px -152px;
  width: 34px;
  height: 16px;
  display: inline-block;
}

span.pdo_yesno {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -274px -152px;
  width: 34px;
  height: 16px;
  display: inline-block;
}

span.pdo_nono {
  background: transparent url('../images/platin-icons-sprite.png?q=-1') no-repeat -309px -152px;
  width: 34px;
  height: 16px;
  display: inline-block;
}

.taskCustomer {
  width: 95%;
}

.contactContrlerTd {
  width: 15px;
}

.taskCustomerContent {
  background: #eee;
}

.taskCustomerTd {
  padding: 4px;
}

.taskCustomerRemoveRelationTd {
  padding: 4px;
}

.taskCustomerRemoveRelationTd a {
  text-decoration: underline;
}

.taskCustomerContactLp {
  font-weight: bold;
  text-align: center;
  width: 20px;
}

.taskCustomerContactDate {
  text-align: center;
  font-weight: bold;
}

.taskCustomerContactIsClosed {
  text-align: center;
  font-weight: bold;
}

.taskCustomerContactAction {
  text-align: center;
  font-weight: bold;
}

#avcal {
  width: 280px;
  background: #fff;
  zoom: 1;
  padding: 3px 0 0 3px;
}

.avcal_ctr {
  position: relative;
  width: 280px;
  min-height: 582px;
  filter: Alpha(Opacity=100);
  opacity: 1;
  z-index: 1110;
  -moz-box-shadow: 3px 3px 6px 2px #ddd;
  -webkit-box-shadow: 3px 3px 6px 2px #ddd;
  box-shadow: 3px 3px 6px 2px #ddd;
}

.avcal_inner {
  background: #fff;
  border: 1px solid #ddd;
}

.avcal_progress {
  width: 280px;
  height: 462px;
  text-align: center;
  padding-top: 98px;
}

.avcal_progress_text {
  color: #04a3e4;
  font-size: 18px;
  font-weight: bold;
}

.avcal_error {
  width: 280px;
  height: 462px;
  text-align: center;
  padding-top: 198px;
}

.avcal_error_text {
  color: #f00;
  font-size: 18px;
  font-weight: bold;
}

#avcal_header {
  width: 100%;
  color: #fff;
  background: #04a3e4;
  border-top: 2px solid #08afef;
  border-bottom: 2px solid #0095d5;
  padding: 4px 0;
  text-align: left;
  margin: 1px 0 0;
}

#avcal_title {
  float: left;
  text-indent: 7px;
  color: #fff;
  font-weight: bold;
  font-size: 11px;
}

#avcal_info_btn {
  float: right;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  font-size: 13px;
  margin-right: 7px;
  margin-top: -3px;
}

#avcal_close_btn {
  float: right;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  font-size: 13px;
  margin-right: 7px;
  margin-top: -3px;
}

#avcal_close_btn:hover,
#avcal_close_btn:active {
  color: #0095d5;
}

.avcal_dateSwitcher {
  float: right;
  width: 240px;
  margin: 8px 0 5px;
}

.avcal_dateSwitcher_button {
  float: left;
  width: 32px;
  height: 32px;
  cursor: pointer;
  border: 0 solid white;
  padding: 0;
  outline: 0;
  margin: 0;
}

#avcal_dateSwitcher_leftbutton {
  background: no-repeat url('../images/arrow_left32.jpg');
  margin-right: 4px;
}

#avcal_dateSwitcher_leftbutton:hover {
  background: no-repeat url('../images/arrow_left32_h.jpg');
}

#avcal_dateSwitcher_leftbutton:active {
  background: no-repeat url('../images/arrow_left32_p.jpg');
}

#avcal_dateSwitcher_currentDate {
  float: left;
  width: 162px;
  height: 25px;
  font-weight: bold;
  font-size: 12px;
  padding-top: 8px;
  text-align: center;
  white-space: nowrap;
}

#avcal_dateSwitcher_rightbutton {
  background: no-repeat url('../images/arrow_right32.jpg');
  margin-left: 4px;
}

#avcal_dateSwitcher_rightbutton:hover {
  background: no-repeat url('../images/arrow_right32_h.jpg');
}

#avcal_dateSwitcher_rightbutton:active {
  background: no-repeat url('../images/arrow_right32_p.jpg');
}

#avcal_content {
  width: 100%;
}

#avcal_rowsHeader {
  float: left;
  width: 40px;
}

.avcal_rowElementEmpty {
  width: 39px;
  height: 29px;
  padding: 0;
  border-top: 0;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-left: none;
}

.avcal_rowElement {
  width: 32px;
  height: 27px;
  color: #777;
  font-style: italic;
  font-size: 11px;
  text-align: right;
  padding: 11px 7px 0 0;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-left: none;
}

#avcal_columns {
  position: relative;
  margin-left: 40px;
}

.avcal_column {
  float: left;
  width: 34px;
}

.dayoff {
  color: #f00 !important;
  background-color: #faf0f5;
}

.avcal_columnHeader {
  width: 33px;
  height: 22px;
  color: #777;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  padding: 7px 0 0 0;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.avcal_columnElement {
  width: 33px;
  height: 18px;
  padding: 10px 0;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.avcal_columnElementP1 {
  width: 32px;
  height: 9px;
  border-top: 1px dashed #ddd;
}

.avcal_columnElementP2 {
  width: 32px;
  height: 8px;
  border-top: 1px solid #ddd;
  border-bottom: 1px dashed #ddd;
}

#avcal_timeBlocks {
  height: 374px;
  margin-left: 40px;
}

.avcal_box {
  border: 1px solid black;
  cursor: help;
}

#avcal_info {
  width: 200px;
  z-index: 9000;
  background: #e5effa;
  color: #777;
  border: 1px solid #26517c;
  padding: 7px;
  font-size: 9px;
}

.avcal_info_title {
  font-weight: bold;
  font-size: 11px;
}

.avcal_info_legendelement {
  margin: 11px;
}

.avcal_info_legendelement_colorbox {
  float: left;
  background: #fff;
  border: 1px solid black;
}

.avcal_info_legendelement_colorboxinner {
  height: 16px;
  width: 24px;
  filter: Alpha(Opacity=50);
  opacity: 0.5;
}

.avcal_info_legendelement_desc {
  float: left;
  margin-left: 11px;
  padding-top: 4px;
  font-weight: bold;
}
