* {
  border: 0;
  box-sizing: border-box;
  scrollbar-color: #003574 white;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: white;
  }

  &::-webkit-scrollbar-thumb {
    background: #003574;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #003574;
    border-radius: 2px;
  }
}

body {
  color: #2e4961;
  font-family: 'PKO Bank Polski';
  font-size: 13px;
  margin: 0;
}

:root {
  --container-width: 1300px;
  --background: white;
  --brand-headertop-background: #094e98;
  --brand-headertop-background-d10: #094e98;
  --brand-headertop-fontcolor: #000;
  --brand-primary: #e4202c;
  --brand-secondary: #003574;
  --brand-tertiary: #8b99a5;
  --brand-grey: #e5e5e5;
  --brand-grey-hover: #f2f2f2;
  --brand-lightgrey: #f3f3f3;
  --brand-border-radius: 0px;
  --brand-border-width: 1px;
  --brand-primary-fontfamily: PKOBankPolski;
  --brand-secondary-fontfamily: PKOBankPolski;
  --support-gray: #f3f3f3;
  --support-grey: #dedede;
  --support-tag-grey: #8b99a5;
  --support-grey-light: #e5e5e5;
  --support-grey-dark: #585858;
  --support-grey-background: #f7f7f7;
  --support-red-lighter: #f2453d;
  --support-blue: #009bfa;
  --support-tab-grey: #888;
  --widget-title-size: 24px;
  --widget-title-lineheight: 24px;
  --widget-title-weight: 700;
  --widget-title-pb: 1rem;
  --widget-title-mb: 2rem;
  --tags-height: 20px;
  --tags-p: 0 0.75rem;
  --tags-bgc: var(--brand-tertiary);
  --tags-bgc-active: var(--brand-secondary);
}

#container {
  display: grid;
  min-height: 100vh;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
  width: 100%;
  margin: 0 auto;
}

body.iframe-html {
  background: white;
}

a {
  text-decoration: none;
  color: #2e4961;
}

button,
input[type='button'] {
  background-color: #003574;
  border-radius: 4px 0;
  box-sizing: border-box;
  color: #fff;
  font-family: 'PKO Bank Polski', Helvetica, sans-serif;
  font-size: 16px;
  line-height: 13px;
  height: 40px;
  min-width: 180px;
  padding: 13px 15px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
}

button:hover,
input[type='button'] {
  background-color: #001e4b;
}

.content-container {
  width: var(--container-width);
  max-width: 100%;
  margin: 0 auto;
  padding: 0 35px;
}

#sidebar {
  width: 180px;
  margin-right: 10px;
  float: left;
}

#main {
  width: 790px;
  float: left;
}

#main-full {
  width: 100%;
}

#main-center {
  width: 580px;
  float: left;
}

#main-right {
  width: 180px;
  margin-left: 20px;
  float: right;
}

#main-380 {
  width: 390px;
  margin-right: 10px;
  float: left;
}

#main-380-2 {
  width: 390px;
  float: left;
}

#main-780 {
  width: 780px;
  clear: both;
  margin-left: 200px;
}

#main-980 {
  width: 980px;
  clear: both;
}

.withArrow:after {
  background: url('/src/images/arrow.svg') no-repeat 50%;
  content: '';
  display: inline-block;
  height: 24px;
  width: 10px;
  transition: transform 0.3s ease-out;
}

.current.withArrow:after {
  transform: rotate(180deg);
}

.hcenter {
  text-align: center;
}

.hleft {
  text-align: left;
}

.hright {
  text-align: right;
}

.vtop {
  vertical-align: top;
}

.vmiddle {
  vertical-align: middle;
}

.vbottom {
  vertical-align: bottom;
}

.no-border {
  border: 0;
}

.no-top-border {
  border-top: 0;
}

.no-right-border {
  border-right: 0;
}

.no-bottom-border {
  border-bottom: 0;
}

.no-left-border {
  border-left: 0;
}

.wrapper {
  clear: both;
}

.bd {
  font-weight: bold;
}

.Red {
  color: #e61717;
}

.Green {
  color: #12b812;
}

.Orange {
  color: #e6ac17;
}

.clearfix {
  clear: both;
}

#tooltip {
  position: absolute;
  z-index: 999;
  width: 230px;
  border: 1px solid #a9c5d1;
}

#tooltip h4 {
  padding: 5px 10px;
  background-color: #a9c5d1;
  font-weight: bold;
  text-align: center;
  color: #fff;
  font-size: 11px;
}

#tooltip p {
  margin: 0;
  color: #2e4961;
  background-color: #e4edf1;
  font-size: 11px;
  font-weight: normal;
  padding: 8px 10px;
}

#top {
  height: 68px;
  background: url('@images/platin-logo.png') 0 no-repeat;
}

#top ul li {
  list-style-type: none;
  text-align: right;
}

#top ul li span {
  font-weight: bold;
  color: #73b1e8;
}

#top div {
  cursor: pointer;
}

#top div.klient {
  margin: 5px 0 5px 5px;
  width: 20px;
  height: 56px;
  background: url('../images/klient.png') no-repeat;
  float: right;
}

#top div.operator {
  margin: 5px 0 5px 5px;
  width: 20px;
  height: 56px;
  background: url('../images/operator.png') no-repeat;
  float: right;
}

#top div.sprawa {
  margin: 5px 0 5px 5px;
  width: 20px;
  height: 56px;
  background: url('../images/sprawa.png') no-repeat;
  float: right;
}

#top ul li img {
  vertical-align: middle;
}

#head-container {
  width: var(--container-width);
  max-width: calc(100% - 40px);
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.border-bottom {
  border-bottom: 1px solid var(--support-grey-light);
}

#main-content {
  padding: 30px 35px;
  background: white;
}

#nav-right {
  float: right;
}

#messages-container {
  position: relative;
}

#messages-container .dropdown {
  display: none;
  position: absolute;
  right: 0;
  top: 31px;
  width: 350px;
  border: 3px solid #ccc;
  padding: 4px 30px 4px 4px;
  background-color: white;
}

#messages-container.dropdown-visible .dropdown {
  display: block;
}

#messages-container .dropdown ul li {
  margin-left: 10px;
}

/* reset css dla buttona w kontenerze */
#messages-container .button {
  background: none;
  border: none;
  padding: 0 !important;
  margin: 0;
  width: auto;
}

#messages-container-button.button {
  background: url(../images/platin-icons-sprite.png) no-repeat -114px 0;
  width: 16px;
  height: 16px;
  display: inline-block;
  border: 8px solid transparent;
  background-clip: content-box;
}

#messages-container-button.button:hover {
  background-color: #a9c5d1;
  border-color: #a9c5d1;
}

#messages-container-delete-all-button.button {
  position: absolute;
  right: 4px;
  bottom: 4px;
  background: url(../images/platin-icons-sprite.png) no-repeat -42px -102px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

/* style z Platin CRM */
#messages-container-counter {
  position: relative;
  top: -7px;
  right: -10px;
  display: block;
  height: 14px;
  font-size: 10px;
  color: #ffffff;
  text-align: center;
  border-radius: 7px;
  padding: 1px 1px 0;
  width: auto;
  min-width: 14px;
  font-family: 'Tahoma', sans-serif;
  line-height: 13px;
  background-color: #ca171d;
}

#menu li li a {
  padding: 4px;
}

#breadcrumb {
  width: 100%;
  clear: both;
  margin-top: 3px;
  background-color: #e0e0e0;
  font-size: 10px;
}

#breadcrumb p {
  padding: 5px 25px;
  float: right;
}

#breadcrumb p:first-child {
  padding: 5px 25px;
  float: left;
}

#breadcrumb p span {
  font-weight: bold;
  background: url('../images/arrow.png') 4px 2px no-repeat;
  padding-left: 16px;
}

#breadcrumb p span:first-child {
  background: none;
  padding-left: 8px;
}

#breadcrumb2 {
  clear: both;
  margin-top: 3px;
  font-size: 10px;
}

#breadcrumb2 p {
  padding: 5px 10px;
  float: right;
}

#breadcrumb2 p:first-child {
  padding: 5px 25px;
  float: left;
}

#breadcrumb2 p span {
  font-weight: bold;
  background: url('../images/arrow.png') 4px 2px no-repeat;
  padding-left: 16px;
}

#breadcrumb2 p span:first-child {
  background: none;
  padding-left: 8px;
}

#breadcrumb3 {
  float: left;
  margin-top: 48px;
  font-size: 9px;
}

#breadcrumb3 p {
  padding: 3px 10px;
  float: right;
}

#breadcrumb3 p:first-child {
  padding: 3px 15px;
  float: left;
}

#breadcrumb3 p span {
  font-weight: bold;
  background: url('../images/arrow.png') 4px 2px no-repeat;
  padding-left: 16px;
}

#breadcrumb3 p span:first-child {
  background: none;
  padding-left: 6px;
}

#submenu {
  margin-top: 20px;
  width: 100%;
  font-size: 10px;
}

#submenu2 {
  width: 100%;
  clear: both;
  margin-top: 3px;
  background-color: #e0ebef;
  font-size: 10px;
}

#submenu ul,
#submenu2 ul {
  float: left;
  padding: 0 15px;
}

#submenu li {
  list-style-type: none;
  float: left;
  padding: 5px 10px;
  position: relative;
  font-weight: bold;
  background: url('../images/submenu-divider.png') 0 9px no-repeat;
}

#submenu2 li {
  list-style-type: none;
  float: left;
  padding: 0 2px 0 3px;
  position: relative;
  font-weight: bold;
  background: url('../images/submenu-divider.png') 0 9px no-repeat;
}

#submenu li:first-child,
#submenu2 li:first-child {
  background: 0;
}

#submenu ul li:hover,
#submenu ul li:hover a {
  background-color: #a9c5d1;
  color: #fff;
}

#submenu2 li.bg0 {
  background: 0;
}

#submenu2 li a {
  display: block;
  padding: 5px 8px 5px 8px;
}

#submenu2 ul li:hover a {
  background-color: #d3e2e7;
}

.nav-box {
  float: right;
  overflow: visible;
}

.nav-box-ul {
  border-left: none;
  margin: 7px 7px 7px 0;
  padding-left: 6px;
}

.z10 {
  z-index: 10;
}

.z20 {
  z-index: 20;
}

.z30 {
  z-index: 30;
}

.z40 {
  z-index: 40;
}

.progressBarOutside {
  display: inline;
  width: 102px;
  height: 11px;
  padding: 1px;
  background-image: url('../images/progress-bar-filled.png');
  float: right;
  margin-top: 1px;
  margin-left: 4px;
}

.progressBarInside {
  width: 102px;
  height: 11px;
  text-align: center;
  font-size: 80%;
  font-weight: normal;
  background-image: url('../images/progress-bar-empty.png');
  background-repeat: no-repeat;
}

#sidebar h3 {
  font-size: 11px;
  color: #fff;
  background-color: #dfa14a;
  text-align: center;
  padding: 5px;
  margin-bottom: 1px;
  margin-top: 10px;
}

#sidebar h3:first-child {
  margin-top: 0;
}

#sidebar ul li {
  list-style-type: none;
  display: block;
  padding: 5px 6px 5px 24px;
  margin-bottom: 1px;
  background: url('../images/arrow-old.gif') 8px 9px no-repeat #ffecd2;
}

#sidebar ul li:hover {
  background: url('../images/arrow-old.gif') 8px 9px no-repeat #ffd9a2;
}

#sidebar h3.blue {
  background-color: #73b1e8;
}

#sidebar ul.blue li {
  background: url('../images/arrow-old.gif') 8px 9px no-repeat #d5ebff;
}

#sidebar ul.blue li:hover {
  background: url('../images/arrow-old.gif') 8px 9px no-repeat #a9d6ff;
}

#sidebar ul.blue2 a {
  float: left;
  width: 120px;
}

#sidebar ul.blue2 span {
  float: right;
}

#sidebar ul.blue2 span a {
  float: none;
}

#sidebar ul.blue2 span,
#sidebar ul.blue2 span a {
  color: #e61717;
  font-size: 95%;
}

#sidebar ul.blue3 {
  border-bottom: 1px solid #d5ebff;
  background-color: #fff;
}

#sidebar ul.blue3 li {
  padding-left: 12px;
  border-left: 1px solid #d5ebff;
  border-right: 1px solid #d5ebff;
  margin: 0;
}

#sidebar ul.blue3 li.green {
  background: url('../images/green_button.png') 155px 4px no-repeat;
}

#sidebar ul.blue3 li.green:hover {
  background: url('../images/green_button.png') 155px 4px no-repeat #d5ebff;
}

#sidebar ul.blue3 li.red {
  background: url('../images/red_button.png') 155px 4px no-repeat;
}

#sidebar ul.blue3 li.red:hover {
  background: url('../images/red_button.png') 155px 4px no-repeat #d5ebff;
}

#sidebar ul.blue3 li.orange {
  background: url('../images/orange_button.png') 155px 4px no-repeat;
}

#sidebar ul.blue3 li.orange:hover {
  background: url('../images/orange_button.png') 155px 4px no-repeat #d5ebff;
}

#sidebar ul.blue3 li.blank {
  background: url('../images/blank_button.png') 155px 4px no-repeat;
}

#sidebar ul.blue3 li.blank:hover {
  background: url('../images/blank_button.png') 155px 4px no-repeat #d5ebff;
}

#sidebar ul.blue3 li.grey {
  background: url('../images/grey_button.png') 155px 4px no-repeat;
}

#sidebar ul.blue3 li.grey a {
  color: #999;
}

#sidebar ul.blue3 li.grey:hover {
  background: url('../images/grey_button.png') 155px 4px no-repeat #efefef;
}

#sidebar ul.blue4 {
  border-bottom: 1px solid #d5ebff;
  background-color: #fff;
}

#sidebar ul.blue4 li {
  padding-left: 12px;
  border-left: 1px solid #d5ebff;
  border-right: 1px solid #d5ebff;
  margin: 0;
}

#sidebar ul.blue4 li {
  background: 0;
}

#sidebar ul.blue4 li span {
  float: right;
  margin-right: 5px;
  color: red;
  font-weight: bold;
}

#sidebar ul.blue4 li:hover {
  background-color: #d5ebff;
}

#sidebar ul.blue4 li.l1 {
  text-transform: uppercase;
  font-weight: bold;
}

#sidebar ul.blue4 li.l2 a {
  margin-left: 10px;
}

#sidebar ul.blue4 li.divider hr {
  border: 1px solid #d5ebff;
  margin-right: 5px;
}

#sidebar ul.blue4 li.divider:hover {
  background-color: #fff;
}

#sidebar ul.blue5 {
  border-bottom: 1px solid #d5ebff;
  border-left: 1px solid #d5ebff;
  border-right: 1px solid #d5ebff;
  background-color: #fff;
}

#sidebar ul.blue5 li {
  margin: 0;
  background: 0;
  padding-left: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
}

#sidebar ul.blue5 li a {
  display: block;
  float: left;
  width: 110px;
}

#sidebar ul.blue5 li span {
  float: right;
  margin-right: 5px;
  font-weight: bold;
}

#sidebar ul.blue5 li:hover {
  background-color: #d5ebff;
}

#sidebar ul.blue5 li.l1 {
  text-transform: uppercase;
  font-weight: bold;
}

#sidebar ul.blue5 li.l2 a {
  margin-left: 10px;
}

#sidebar ul.blue5 li.divider {
  background: url('../images/bg-divider.png') 10px 3px no-repeat #fff;
}

#sidebar ul.blue5 li.divider-blank {
  height: 0;
  background: #fff;
}

#sidebar ul.blue5 li.divider:hover {
  background-color: #fff;
}

#main-right h3 {
  font-size: 11px;
  color: #fff;
  background-color: #dfa14a;
  text-align: center;
  padding: 5px;
  margin-bottom: 1px;
  margin-top: 10px;
}

#main-right h3:first-child {
  margin-top: 0;
}

#main-right ul li {
  list-style-type: none;
  display: block;
  padding: 5px 12px 5px 12px;
  margin-bottom: 1px;
  background-color: #ffecd2;
}

#main-right li.indent {
  padding-left: 24px;
}

#main-right ul li a {
  float: left;
}

#main-right ul li span {
  float: right;
  font-weight: bold;
  color: #dfa14a;
}

#main-right ul li:hover {
  background-color: #ffd9a2;
}

#sidebar ul.orange span {
  float: right;
}

#sidebar ul.orange li {
  background-image: none;
  color: Red;
  background: url('../images/arrow-old.gif') no-repeat scroll 8px 9px #ffecd2;
}

#sidebar ul.orange li:hover {
  background: url('../images/arrow-old.gif') 8px 9px no-repeat #ffd9a2;
}

#sidebar ul.orange span a {
  float: none;
}

#sidebar ul.orange li.grupa {
  background: url('../images/arrow-old.gif') no-repeat scroll 8px 9px #ffecd2;
  text-transform: uppercase;
}

#sidebar ul.orange a {
  float: left;
  width: 120px;
}

#sidebar ul.orange span {
  float: right;
}

#sidebar ul.orange span,
#sidebar ul.orange span a {
  color: #e61717;
  font-size: 95%;
}

#sidebar .steps ul li {
  background: #d1d1d1;
  padding: 0 0 0 12px;
  margin-bottom: 4px;
}

#sidebar .steps ul li a {
  display: block;
  background: url('../images/step1bg.png') repeat-x #fff;
  padding: 7px 10px;
  border: 1px solid #e5e5e5;
  border-left: none;
  font-size: 16px;
  color: #646464;
}

#sidebar .steps ul li a:hover {
  border-color: #d1d1d1;
}

#sidebar .steps ul li a span {
  display: block;
  font-size: 11px;
}

#sidebar .steps ul li.current {
  background: #2f4962;
}

#sidebar .steps ul li.current a {
  background: url('../images/step2bg.png') repeat-x #fff;
}

#sidebar .steps ul li.current a {
  color: #2f4962;
  border-color: #d9ebff;
}

#sidebar .steps ul li.current a:hover {
  border-color: #b4daff;
}

h1 {
  color: #000;
  font-family: 'PKO Bank Polski', Helvetica, sans-serif;
  font-size: 33px;
  font-weight: 400;
  line-height: 48px;
  margin: 0;
  max-width: 100%;
  padding: 0 0 16px;
}

#main h1 {
  margin-top: 10px;
  font-size: 11px;
  text-align: center;
  color: #fff;
  background-color: #73b1e8;
  text-transform: uppercase;
  padding: 6px 0;
}

#main h1:first-child {
  margin-top: 0;
}

.item {
  background: #fff url('../images/bg-blue-1.png') repeat-x;
  padding: 10px 10px 10px 10px;
  margin-top: 10px;
  border: 1px solid #a9d6ff;
}

.item2 {
  background-color: #f9f9f9;
  padding: 10px 10px 10px 10px;
  margin-top: 10px;
  border: 1px solid #a9d6ff;
}

.item:first-child {
  margin-top: 0;
}

.item h2,
.item2 h2 {
  font-size: 14px;
  font-weight: normal;
  text-transform: uppercase;
  margin-bottom: 8px;
  text-align: center;
  margin-top: 14px;
}

.item h2:first-child,
.item2 h2:first-child {
  margin-top: 0;
}

.buttons {
  padding: 10px 0;
  text-align: right;
  clear: both;
}

.small-buttons {
  padding: 5px 0;
  text-align: right;
  clear: both;
}

.small-buttons a {
  margin-right: 15px;
}

.small-buttons input {
  font-size: 11px;
  font-weight: bold;
  padding: 2px 6px;
  width: auto;
}

.small-links {
  padding: 5px 0;
  text-align: right;
  clear: both;
}

.small-links a.add {
  margin-right: 24px;
  padding-left: 16px;
  /* 404 */
  /* background: url('../img/ico-12/add.png') bottom left no-repeat; */
  text-decoration: underline;
}

.msg-green {
  display: block;
  margin: 0 auto;
  width: 660px;
  background: url('../images/msg-green-2.png') bottom center no-repeat;
  padding-bottom: 3px;
}

.msg-green span {
  display: block;
  padding: 23px 20px 20px 20px;
  background: url('../images/msg-green-1.png') top center no-repeat;
  font-size: 14px;
  color: #12b812;
}

.msg-green-2 {
  display: block;
  margin: 0 auto;
  width: 660px;
  background: url('../images/msg-green-2.png') bottom center no-repeat;
  padding-bottom: 3px;
}

.msg-green-2 span {
  display: block;
  padding: 16px 20px 13px 20px;
  background: url('../images/msg-green-1.png') top center no-repeat;
  font-size: 12px;
  color: #12b812;
}

.msg-red {
  display: block;
  margin: 0 auto;
  width: 660px;
  background: url('../images/msg-red-2.png') bottom center no-repeat;
  padding-bottom: 3px;
}

.msg-red span {
  display: block;
  padding: 23px 20px 20px 20px;
  background: url('../images/msg-red-1.png') top center no-repeat;
  font-size: 14px;
  color: #e61717;
}

.msg-orange {
  display: block;
  margin: 0 auto;
  width: 660px;
  background: url('../images/msg-orange-2.png') bottom center no-repeat;
  padding-bottom: 3px;
}

.msg-orange span {
  display: block;
  padding: 23px 20px 20px 20px;
  background: url('../images/msg-orange-1.png') top center no-repeat;
  font-size: 14px;
  color: #e6ac17;
}

.msg-green ul,
.msg-red ul,
.msg-orange ul {
  margin-top: 6px;
  margin-left: 60px;
  list-style-type: square;
  font-size: 12px;
}

.msg-green span img,
.msg-green-2 span img,
.msg-red span img,
.msg-orange span img {
  vertical-align: middle;
  margin-right: 12px;
}

ul.linki {
  margin-top: 10px;
}

ul.linki li {
  list-style-type: none;
  font-size: 14px;
  padding-left: 20px;
  background: url('../images/arr-orange-16.png') 0 2px no-repeat;
  line-height: 140%;
  font-weight: bold;
}

ul.linki li:hover {
  background: url('../images/arr-orange-16-over.png') 0 2px no-repeat;
}

#footer #hd {
  float: left;
  font-size: 11px;
  width: 199px;
  text-align: left;
}

#footer #version {
  float: left;
  font-size: 11px;
  width: 210px;
  text-align: left;
}

#footer #ip {
  float: left;
  font-size: 11px;
  width: 100px;
  text-align: left;
}

#footer a {
  font-weight: bold;
}

#footer #copyright {
  float: right;
  font-size: 11px;
  width: 435px;
  text-align: right;
}

.mapaCheckbox {
  padding: 3px;
}

.mapaCheckbox table {
  margin: 0;
  width: 600px;
}

.mapaCheckbox table tr,
.mapaCheckbox table tr td {
  margin: 0;
  padding: 3px;
}

.mapaCheckbox tr.k {
  font-weight: bold;
  background-color: #a9d6ff;
}

.mapaCheckbox tr.p {
  background-color: #d5ebff;
}

.mapaCheckbox tr.c {
  background-color: #eff7ff;
}

.mapaCheckbox tr.r {
  background-color: #fafafa;
}

.mapaCheckbox tr.t {
  background-color: #fff;
}

.mapaCheckbox .k1 {
  width: 20px;
}

.mapaCheckbox .grube {
  font-weight: bold;
}

#footer {
  border-top: 1px solid #e0e0e0;
  padding: 7px;
  font-size: 10px;
}

#footer p a {
  font-weight: bold;
}

#footer p a:hover {
  text-decoration: underline;
}

table {
  border-collapse: collapse;
}

td.w25 {
  width: 25%;
  padding: 1px 4px;
}

td.w50 {
  width: 50%;
  padding: 1px 4px;
}

td.i160 input[type='text'] {
  width: 160px;
  text-align: right;
}

td.i140 input[type='text'] {
  width: 140px;
  text-align: right;
}

td.s160 select {
  width: 166px;
}

.table-4-col {
  width: 760px;
}

.table-2-col {
  width: 334px;
}

.w714 {
  width: 714px;
}

.table-4-col-wynik {
  width: 100%;
  margin-top: 10px;
}

.table-4-col-wynik td {
  padding: 6px 8px;
  border: 1px solid #d5ebff;
}

.table-4-col-wynik td.l {
  width: 34%;
  text-align: left;
}

.table-4-col-wynik td.r {
  width: 16%;
  text-align: right;
  font-weight: bold;
}

.table-1 {
  width: 100%;
  background-color: #fff;
}

.table-1 thead tr th {
  text-align: center;
  background-color: #d5ebff;
  padding: 5px;
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
}

.table-1 thead tr th:first-child {
  border-left: 1px solid #d5ebff;
}

.table-1 td {
  border: 1px solid #d5ebff;
  padding: 5px;
}

table.table-1 td a {
  border-bottom: 1px solid #2e4961;
}

.table-1 .col1 {
  width: 30px;
  text-align: center;
}

.table-1 tr:hover {
  background-color: #f2f9ff;
}

.table-2-col-zadania {
  width: 100%;
}

.table-2-col-zadania thead th {
  text-align: center;
  background-color: #d5ebff;
  padding: 5px;
  border: 1px solid #d5ebff;
}

.table-2-col-zadania td {
  border: 1px solid #d5ebff;
  padding: 5px;
}

.table-2-col-zadania tr:hover {
  background-color: #f2f9ff;
}

.table-5-col-nieruchomosci-szczegoly {
  width: 100%;
  margin-top: 10px;
}

.table-5-col-nieruchomosci-szczegoly td {
  width: 162px;
  border: 1px solid #d5ebff;
  padding: 5px;
  vertical-align: middle;
}

.table-5-col-nieruchomosci-szczegoly .col5 {
  width: 70px;
  text-align: center;
}

.table-5-col-nieruchomosci-szczegoly thead th {
  text-align: center;
  background-color: #a9d6ff;
  padding: 5px;
  border: 1px solid #d5ebff;
}

tr.row2 th {
  background-color: #a9d6ff;
}

input[type='text'] {
  border: var(--support-grey-dark) 1px solid;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  padding: 10px 12px 10px 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  line-height: 20px;
  min-height: 40px;
  background-color: #fff;
  margin: 0 1px;
}

input[type='text']:focus-visible {
  outline: black 1px solid;
  border-color: rgba(0, 0, 0, 0);
}

input[type='radio'] {
  vertical-align: middle;
  margin-right: 5px;
}

input[type='checkbox'] {
  vertical-align: middle;
  margin-right: 5px;
}

input[readonly='readonly'],
input[disabled='disabled'],
.disabled {
  background-color: #efefef;
  color: #999;
}

input[type='text'].error {
  border: 1px solid #f3bbbb;
  background-color: #fdf0f0;
}

input.search {
  background: url('../images/pko_icon_zoom_2px_32.svg') center no-repeat;
  background-position-x: calc(100% - 6px);
  padding-right: 40px;
}

label {
  cursor: default;
}

select {
  border: 1px solid #ccc;
  padding-left: 2px;
  font-size: 11px;
  font-weight: bold;
}

select option {
  padding: 0 5px;
}

textarea {
  border: 1px solid #ccc;
  padding: 2px;
  font-size: 11px;
  font-weight: bold;
  font-family: Tahoma;
}

.validator {
  display: block;
  width: 166px;
  margin: 2px 0 0 4px;
  padding-bottom: 3px;
  background: url('../images/val166b.png') center bottom no-repeat;
}

.validator span {
  display: block;
  color: #fd0000;
  font-size: 10px;
  font-weight: bold;
  padding: 10px 5px 1px 5px;
  background: url('../images/val166a.png') center top no-repeat;
}

th input[type='text'] {
  border: 0;
  padding: 1px 2px;
  font-size: 9px;
  font-weight: bold;
  vertical-align: middle;
  width: 60px;
}

fieldset .buttons {
  padding-bottom: 0;
}

fieldset {
  border: 1px solid #a9d6ff;
  margin-top: 10px;
  margin-left: 0;
  background: url('../images/bg-blue-2.png') repeat-x;
  padding: 0 10px 10px 10px;
}

fieldset:first-child {
  margin-top: 0;
}

fieldset.f2 a.collapse-1 {
  display: block;
  float: right;
  top: -27px;
  position: relative;
}

legend {
  border: 1px solid #73b1e8;
  padding: 2px 4px;
  background-color: #d5ebff;
  margin-bottom: 10px;
  text-transform: uppercase;
}

fieldset.f2 {
  border: 0;
  margin-top: 10px;
  margin-left: 0;
  background: url('../images/a9d6ff.png') 0 8px repeat-x;
  padding: 0 10px 10px 10px;
}

fieldset:first-child {
  margin-top: 0;
}

fieldset.f2 legend,
fieldset.f2 div legend {
  border: 0;
  padding: 2px 4px;
  background: 0;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: bold;
}

fieldset.no-label {
  padding-top: 20px;
}

fieldset.f3 {
  border: 0;
  background: url('../images/d5ebff.png') 0 8px repeat-x;
  padding: 0;
}

fieldset.f3 legend {
  border: 0;
  background: 0;
  font-weight: bold;
}

fieldset.f3 legend img {
  margin-right: 8px;
}

.inny-produkt {
  background: url('../images/bg-orange-1.png') repeat-x;
  border: 1px solid #ffd9a2;
}

.inny-produkt legend {
  border: 1px solid #ffd9a2;
  background-color: #ffecd2;
}

.inny-produkt input[type='text'] {
  border: 1px solid #ffd9a2;
}

div.display,
div.input,
div.textarea,
div.select,
div.radio,
div.check {
  float: left;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

div.check input {
  margin-top: 15px;
  float: left;
}

div.check label {
  padding: 2px 0;
  margin-top: 15px;
  display: block;
}

div.input label,
div.textarea label,
div.select label {
  display: block;
  margin-bottom: 2px;
}

div.input input,
div.select select,
div.textarea textarea {
  text-align: left;
}

div.display {
  width: 440px;
  min-height: 36px;
}

div.displayMinHeight {
  height: 36px;
  float: right;
  width: 1px;
}

div.heightLikeInput {
  height: 36px;
}

div.displayAsLink strong {
  text-decoration: underline;
}

div.displayAsLink:hover {
  text-decoration: none;
}

div.input,
div.select,
div.check,
div.height1x {
  height: 36px;
}

div.textarea,
div.height2x {
  height: 72px;
}

div.height3x {
  height: 174px;
}

div.height4x {
  height: 232px;
}

div.input,
div.textarea,
div.select,
div.radio,
div.check,
div.module1x {
  width: 172px;
}

div.module2x {
  width: 370px;
}

div.module3x {
  width: 555px;
}

div.module4x {
  width: 740px;
}

div.module5x {
  width: 925px;
}

div.input input,
div.textarea textarea,
div.module1x input,
div.module1x textarea {
  width: 150px;
}

div.select select,
div.module1x select {
  width: 161px;
}

div.module2x input,
div.module2x textarea {
  width: 340px;
}

div.module2x select {
  width: 345px;
}

div.module3x input,
div.module3x textarea {
  width: 525px;
}

div.module3x select {
  width: 531px;
}

div.module4x input,
div.module4x textarea {
  width: 710px;
}

div.module4x select {
  width: 716px;
}

div.module5x input,
div.module5x textarea {
  width: 895px;
}

div.module5x select {
  width: 901px;
}

.switchSearch {
  float: left;
  position: absolute;
  right: 20px;
  top: 14px;
  font-weight: bold;
}

.switchSearch a {
  cursor: pointer;
  text-decoration: underline;
}

.switchSearch a.readonly {
  color: #999;
  text-decoration: none;
}

.switchSearch span {
  font-weight: bold;
  color: #999;
}

.box1 {
  margin-top: 10px;
}

.box1:first-child,
.box2 {
  margin-top: 0;
}

.box1-header,
.box2-header {
  background-color: #73b1e8;
  color: #fff;
}

.box1-header h2,
.box2-header h2 {
  border: 0;
  font-size: 11px;
  color: #fff;
  padding: 5px 0 0 10px;
  float: left;
}

.box1 .navi ul,
.box2 .navi ul {
  padding: 6px 6px 0 0;
}

.box1 .navi ul li,
.box2 .navi ul li {
  list-style-type: none;
  float: right;
  margin-left: 4px;
}

.box1 .navi,
.box2 .navi {
  height: 23px;
  display: block;
}

.box1 .box-content,
.box2 .box-content {
  padding: 0;
  background-color: #fff;
  border: 0;
}

.box2 .box-content {
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #d5ebff;
  border-top: 0;
}

div.paging {
  text-align: center;
  padding-top: 4px;
  border: 1px solid #d5ebff;
  border-top: 0;
}

table.rich-dtascroller-table {
  font-size: 11px;
  margin: 0 auto;
}

table.rich-dtascroller-table td {
  vertical-align: middle;
  font-weight: bold;
}

table.rich-dtascroller-table td img {
  padding-top: 3px;
}

.rich-datascr-inact,
.rich-datascr-act {
  padding: 0 6px;
}

.rich-datascr-button {
  padding: 0 4px;
}

.rich-datascr-act {
  text-decoration: underline;
}

.rich-datascr-inact:hover {
  text-decoration: underline;
}

input[type='password'] {
  border: 1px solid #ccc;
  padding: 1px 2px;
  font-size: 11px;
  font-weight: bold;
  vertical-align: middle;
}

.panels {
  border: 1px solid var(--support-grey);
  margin-top: 10px;
  padding: 25px;
  border-radius: 4px;
}

legend {
  padding-bottom: 8px;
  padding-top: 16px;
  font-size: 21px;
  font-weight: 400;
  font-family: 'PKO Bank Polski', Helvetica, sans-serif;
}

.panels .item {
  border: 0;
  background: 0;
  padding: 0 10px;
}

.panels .panel {
  display: none;
}

.panels .footer {
  text-align: right;
  padding: 5px;
}

.panels .footer a {
  text-decoration: underline;
}

.tabs {
  margin-bottom: -12px;
}

.tabs li a {
  display: block;
  padding: 3px 5px;
}

.tabs ul {
  display: block;
  clear: both;
}

.tabs li {
  list-style-type: none;
  display: block;
  float: left;
  padding: 5px 12px;
  border: 1px solid var(--support-grey-light);
  background-color: var(--support-grey-background);
  margin-right: -7px;
  border-radius: 6px 6px 0 0;
  position: relative;
}

.tabs li.active {
  z-index: 1;
  background-color: white;
  font-weight: bold;
  border-bottom: 1px solid white;
  box-shadow: 5px 0px 4px -4px rgba(0, 0, 0, 0.12);
}

.tabs-2 {
  background: url('../images/a9d6ff.png') 0 56px repeat-x;
}

.tabs-3 {
  background: url('../images/a9d6ff.png') 0 44px repeat-x;
}

.tabs-3 li a {
  padding: 3px 6px;
}
