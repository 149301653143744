div.vxContentLockerContainer {
  position: relative;
}
div.vxContentLocker {
  background-color: black;
  filter: alpha(opacity = 10);
  opacity: 0.1;
  z-index: 9998;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
div.vxContentLockerMessage {
  position: absolute;
  z-index: 9999;
  border-radius: 8px;
  border-color: #000;
  box-shadow: 10px 10px 5px #888;
  background-color: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 160px;
  height: 40px;
  line-height: 40px;
  font-size: 12px;
  vertical-align: middle;
  text-align: center;
  padding: 5px;
}
div.vxContentLockerMessage:before {
  content: url('../images/ui-anim_basic_16x16.gif');
  margin-right: 12px;
}
