.errorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}

.errorContainer h2 {
  font-size: 23px;
  font-weight: 400;
  line-height: 32px;
  padding: 10px 0 5px;
}

.errorContainer p {
  padding-bottom: 20px;
}

.xIcon {
  width: 88px;
  height: 80px;
  margin: 10px 0 5px;
  background: url("../../../../images/x_150.svg") center no-repeat;
}