.iconCircle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #FFCA81;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 20px;
}